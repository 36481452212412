<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-09 10:29:48
 * @LastEditors: Seven
 * @LastEditTime: 2021-08-18 14:11:12
-->
<style lang="scss">
    @import './404.scss';
</style>

<template>
    <div class="error404">
        <div class="error404-body-con">
            <el-card>
                <div class="error404-body-con-title">4<span><i type="ios-navigate-outline"></i></span>4</div>
                <p class="error404-body-con-message">YOU&nbsp;&nbsp;LOOK&nbsp;&nbsp;LOST</p>
                <div class="error404-btn-con">
                    <el-button @click="goHome" size="large" style="width: 200px;" type="text">返回首页</el-button>
                    <el-button @click="backPage" size="large" style="width: 200px;margin-left: 40px;" type="primary">返回上一页</el-button>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error404',
    methods: {
        backPage () {
            this.$router.go(-1);
        },
        goHome () {
            this.$router.push({
                name: 'wbHome'
            });
        }
    }
};
</script>
